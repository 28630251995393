<div class="property-card-outter-wrapper">
    <a class="property-link-outter w-100" [routerLink]="'/property-details/' + propertyDetail?.ListingId">
        <div class="property-card-img">
            <img src="{{ propertyDetail?.Media[0]?.MediaURL }}" class="carousel-img" alt="property image" width="100%">
        </div>
        <div class="property-description-wrapper flex-column">
            <div class="card-property-address">{{propertyDetail?.UnparsedAddress}}</div>
            <div>{{propertyDetail?.BedroomsTotal}}&nbsp; BD | {{propertyDetail?.BathroomsTotalDecimal}}&nbsp;BA |
                {{propertyDetail?.LivingArea}} SQ.FT.</div>
            <div>${{convertDecimalFormat(propertyDetail?.ListPrice)}}</div>
        </div>
    </a>
</div>